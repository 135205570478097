import {fetch} from '@/utils/request.js'

const baseURL = 'http://127.0.0.1:6001'

// 登记手牌
export function inputKeyNo(keyCardCode){
  return fetch(baseURL+`/api/KeyCardRecycle/Input?keyCardCode=${keyCardCode}`)
}

// 吞卡
export function swallowKeyNo(keyCardCode){
  return fetch(baseURL+`/api/KeyCardRecycle/Swallow?keyCardCode=${keyCardCode}`)
}

// 吐卡
export function spitoutKeyNo(keyCardCode){
  return fetch(baseURL+`/api/KeyCardRecycle/Spitout?keyCardCode=${keyCardCode}`)
}



